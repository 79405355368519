var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ServiceActionType } from './action';
import { initialState } from './state';
export var serviceReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ServiceActionType.SET_DATA_FALSE:
            return __assign(__assign({}, state), { isServiceAdded: false, isServiceDeleted: false, isServiceEdited: false, loading: false, error: null });
        case ServiceActionType.GET_SERVICES_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null });
        case ServiceActionType.GET_SERVICES_SUCCESS:
            return __assign(__assign({}, state), { loading: false, services: action.payload });
        case ServiceActionType.GET_SERVICES_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload });
        case ServiceActionType.ADD_SERVICE_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null, isServiceAdded: false });
        case ServiceActionType.ADD_SERVICE_SUCCESS:
            return __assign(__assign({}, state), { loading: false, isServiceAdded: true });
        case ServiceActionType.ADD_SERVICE_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload, isServiceAdded: false });
        case ServiceActionType.DELETE_SERVICE_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null, isServiceDeleted: false });
        case ServiceActionType.DELETE_SERVICE_SUCCESS:
            return __assign(__assign({}, state), { loading: false, isServiceDeleted: true });
        case ServiceActionType.DELETE_SERVICE_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload, isServiceDeleted: false });
        case ServiceActionType.EDIT_SERVICE_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null, isServiceEdited: false });
        case ServiceActionType.EDIT_SERVICE_SUCCESS:
            return __assign(__assign({}, state), { loading: false, isServiceEdited: true });
        case ServiceActionType.EDIT_SERVICE_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload, isServiceEdited: false });
        default:
            return state;
    }
};
