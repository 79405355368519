export var additionalInitialState = {
    education: '',
    aboutMe: '',
};
export var initialState = {
    additional: additionalInitialState,
    isDataChanged: false,
    loading: false,
    error: null,
};
