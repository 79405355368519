export var DeclarationInfoActionType;
(function (DeclarationInfoActionType) {
    DeclarationInfoActionType["GET_DECLARATION_INFO_PENDING"] = "GET_DECLARATION_INFO_PENDING";
    DeclarationInfoActionType["GET_DECLARATION_INFO_SUCCESS"] = "GET_DECLARATION_INFO_SUCCESS";
    DeclarationInfoActionType["GET_DECLARATION_INFO_ERROR"] = "GET_DECLARATION_INFO_ERROR";
    DeclarationInfoActionType["SET_DECLARATION_INFO_PENDING"] = "SET_DECLARATION_INFO_PENDING";
    DeclarationInfoActionType["SET_DECLARATION_INFO_SUCCESS"] = "SET_DECLARATION_INFO_SUCCESS";
    DeclarationInfoActionType["SET_DECLARATION_INFO_ERROR"] = "SET_DECLARATION_INFO_ERROR";
    DeclarationInfoActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(DeclarationInfoActionType || (DeclarationInfoActionType = {}));
