export var DeclarationInitialState = {
    formatted_size: '',
    name: '',
    original_name: '',
    signature: '',
    file_size: 0,
    created_at: '',
    updated_at: '',
    type: '',
    message: null,
};
export var initialState = {
    declaration: DeclarationInitialState,
    isDataChanged: false,
    loading: false,
    error: null,
};
