export var clinicInitialState = {
    full_name: '',
    short_name: '',
    type: '',
    webAddress: '',
};
export var initialState = {
    clinic: clinicInitialState,
    loading: false,
    error: null,
};
