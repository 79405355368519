export var GalleryModalActionTypes;
(function (GalleryModalActionTypes) {
    GalleryModalActionTypes[GalleryModalActionTypes["ShowGalleryModal"] = 0] = "ShowGalleryModal";
    GalleryModalActionTypes[GalleryModalActionTypes["HideGalleryModal"] = 1] = "HideGalleryModal";
})(GalleryModalActionTypes || (GalleryModalActionTypes = {}));
export function showGalleryModal() {
    return {
        type: GalleryModalActionTypes.ShowGalleryModal,
    };
}
export function hideGalleryModal() {
    return {
        type: GalleryModalActionTypes.HideGalleryModal,
    };
}
