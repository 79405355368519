export var ServiceModalActionTypes;
(function (ServiceModalActionTypes) {
    ServiceModalActionTypes[ServiceModalActionTypes["ShowServiceModal"] = 0] = "ShowServiceModal";
    ServiceModalActionTypes[ServiceModalActionTypes["HideServiceModal"] = 1] = "HideServiceModal";
    ServiceModalActionTypes[ServiceModalActionTypes["ShowEditServiceModal"] = 2] = "ShowEditServiceModal";
    ServiceModalActionTypes[ServiceModalActionTypes["HideEditServiceModal"] = 3] = "HideEditServiceModal";
    ServiceModalActionTypes[ServiceModalActionTypes["ShowServiceSuccessfullyModal"] = 4] = "ShowServiceSuccessfullyModal";
    ServiceModalActionTypes[ServiceModalActionTypes["HideServiceSuccessfullyModal"] = 5] = "HideServiceSuccessfullyModal";
})(ServiceModalActionTypes || (ServiceModalActionTypes = {}));
export function showServiceModal() {
    return {
        type: ServiceModalActionTypes.ShowServiceModal,
    };
}
export function hideServiceModal() {
    return {
        type: ServiceModalActionTypes.HideServiceModal,
    };
}
export function showEditServiceModal() {
    return {
        type: ServiceModalActionTypes.ShowEditServiceModal,
    };
}
export function hideEditServiceModal() {
    return {
        type: ServiceModalActionTypes.HideEditServiceModal,
    };
}
export function showServiceSuccessfullyModal() {
    return {
        type: ServiceModalActionTypes.ShowServiceSuccessfullyModal,
    };
}
export function hideServiceSuccessfullyModal() {
    return {
        type: ServiceModalActionTypes.HideServiceSuccessfullyModal,
    };
}
