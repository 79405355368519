export var ReferencesActionType;
(function (ReferencesActionType) {
    ReferencesActionType["GET_REFERENCES_PENDING"] = "GET_REFERENCES_PENDING";
    ReferencesActionType["GET_REFERENCES_SUCCESS"] = "GET_REFERENCES_SUCCESS";
    ReferencesActionType["GET_REFERENCES_ERROR"] = "GET_REFERENCES_ERROR";
    ReferencesActionType["ADD_REFERENCE_PENDING"] = "ADD_REFERENCE_PENDING";
    ReferencesActionType["ADD_REFERENCE_SUCCESS"] = "ADD_REFERENCE_SUCCESS";
    ReferencesActionType["ADD_REFERENCE_ERROR"] = "ADD_REFERENCE_ERROR";
    ReferencesActionType["DELETE_REFERENCE_PENDING"] = "DELETE_REFERENCE_PENDING";
    ReferencesActionType["DELETE_REFERENCE_SUCCESS"] = "DELETE_REFERENCE_SUCCESS";
    ReferencesActionType["DELETE_REFERENCE_ERROR"] = "DELETE_REFERENCE_ERROR";
    ReferencesActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(ReferencesActionType || (ReferencesActionType = {}));
