export var AdditionalInfoActionType;
(function (AdditionalInfoActionType) {
    AdditionalInfoActionType["GET_ADDITIONAL_INFO_PENDING"] = "GET_ADDITIONAL_INFO_PENDING";
    AdditionalInfoActionType["GET_ADDITIONAL_INFO_SUCCESS"] = "GET_ADDITIONAL_INFO_SUCCESS";
    AdditionalInfoActionType["GET_ADDITIONAL_INFO_ERROR"] = "GET_ADDITIONAL_INFO_ERROR";
    AdditionalInfoActionType["SET_ADDITIONAL_INFO_PENDING"] = "SET_ADDITIONAL_INFO_PENDING";
    AdditionalInfoActionType["SET_ADDITIONAL_INFO_SUCCESS"] = "SET_ADDITIONAL_INFO_SUCCESS";
    AdditionalInfoActionType["SET_ADDITIONAL_INFO_ERROR"] = "SET_ADDITIONAL_INFO_ERROR";
    AdditionalInfoActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(AdditionalInfoActionType || (AdditionalInfoActionType = {}));
