export var ValuesInitialState = {
    id: 0,
    value: 0,
    date: '',
    measureId: 0,
    recordId: 0,
    unitTitle: '',
    measureTitle: '',
    dangerousAbove: 0,
    dangerousBelow: 0,
    color: '',
    dangerousAboveColor: '',
    dangerousBelowColor: '',
    min: '',
    max: '',
    listData: {
        value: 0,
        color: '',
    },
};
export var initialState = {
    values: [ValuesInitialState],
    isValueCreated: false,
    isValueDeleted: false,
    loading: false,
    error: null,
};
