import { RecordsFoldersActionType } from './action';
export var fetchGetRecordsFoldersBegin = function () { return ({
    type: RecordsFoldersActionType.GET_FOLDERS_PENDING,
}); };
export var fetchGetRecordsFoldersSuccess = function (folders) { return ({
    type: RecordsFoldersActionType.GET_FOLDERS_SUCCESS,
    payload: folders,
}); };
export var fetchGetRecordsFoldersFailure = function (error) { return ({
    type: RecordsFoldersActionType.GET_FOLDERS_ERROR,
    payload: { error: error },
}); };
export var fetchAddRecorsFolderBegin = function () { return ({
    type: RecordsFoldersActionType.ADD_FOLDER_PENDING,
}); };
export var fetchAddRecorsFolderSuccess = function () { return ({
    type: RecordsFoldersActionType.ADD_FOLDER_SUCCESS,
}); };
export var fetchAddRecorsFolderFailure = function (error) { return ({
    type: RecordsFoldersActionType.ADD_FOLDER_ERROR,
    payload: { error: error },
}); };
export var fetchDeleteRecorsFolderBegin = function () { return ({
    type: RecordsFoldersActionType.DELETE_FOLDER_PENDING,
}); };
export var fetchDeleteRecorsFolderSuccess = function () { return ({
    type: RecordsFoldersActionType.DELETE_FOLDER_SUCCESS,
}); };
export var fetchDeleteRecorsFolderFailure = function (error) { return ({
    type: RecordsFoldersActionType.DELETE_FOLDER_ERROR,
    payload: { error: error },
}); };
export var fetchEditRecorsFolderBegin = function () { return ({
    type: RecordsFoldersActionType.EDIT_FOLDER_PENDING,
}); };
export var fetchEditRecorsFolderSuccess = function () { return ({
    type: RecordsFoldersActionType.EDIT_FOLDER_SUCCESS,
}); };
export var fetchEditRecorsFolderFailure = function (error) { return ({
    type: RecordsFoldersActionType.EDIT_FOLDER_ERROR,
    payload: { error: error },
}); };
export var setRecordFoldersDataChangedFalse = function () { return ({
    type: RecordsFoldersActionType.SET_DATA_FALSE,
}); };
