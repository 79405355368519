var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fetchDeleteAllViewedNotificationsSuccess, fetchDeleteOneNotificationBegin, fetchDeleteOneNotificationFailure, fetchDeleteOneNotificationSuccess, fetchGetAllNotificationsBegin, fetchGetAllNotificationsFailure, fetchGetAllNotificationsSuccess, fetchMarkAllNotificationsBegin, fetchMarkAllNotificationsFailure, fetchMarkAllNotificationsSuccess, fetchMarkOneNotificationBegin, fetchMarkOneNotificationFailure, fetchMarkOneNotificationSuccess, } from './factory';
export var getAllNotifications = function () {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(fetchGetAllNotificationsBegin());
            try {
                fetch('/api/v1/notifications/get/all?limit=10')
                    .then(function (resp) {
                    return resp.json();
                })
                    .then(function (data) {
                    dispatch(fetchGetAllNotificationsSuccess(data.notifications));
                });
            }
            catch (err) {
                dispatch(fetchGetAllNotificationsFailure(err));
            }
            return [2 /*return*/];
        });
    }); };
};
export var markAllNotifications = function () {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(fetchMarkAllNotificationsBegin());
            try {
                fetch('/api/v1/notifications/mark/all/read', {
                    method: 'POST',
                }).then(function (resp) {
                    if (resp.status == 200) {
                        dispatch(fetchMarkAllNotificationsSuccess());
                    }
                    return resp.json();
                });
            }
            catch (err) {
                dispatch(fetchMarkAllNotificationsFailure(err));
            }
            return [2 /*return*/];
        });
    }); };
};
export var markOneNotification = function (id) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(fetchMarkOneNotificationBegin());
            try {
                fetch("/api/v1/notification/mark/read/".concat(id), {
                    method: 'POST',
                }).then(function (resp) {
                    if (resp.status == 200) {
                        dispatch(fetchMarkOneNotificationSuccess());
                    }
                    return resp.json();
                });
            }
            catch (err) {
                dispatch(fetchMarkOneNotificationFailure(err));
            }
            return [2 /*return*/];
        });
    }); };
};
export var deleteAllViewedNotifications = function () {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            //dispatch(fetchDeleteAllViewedNotificationsBegin());
            //try {
            //  fetch(`/api/v1/notification/delete/`, {
            //    method: 'DELETE',
            //    headers: {
            //      'Content-Type': 'application/json',
            //    },
            //  }).then(resp => {
            //    console.log(resp);
            //    if (resp.status == 200) {
            //      dispatch(fetchDeleteAllViewedNotificationsSuccess());
            //    }
            //    return resp.json();
            //  });
            //} catch (err: any) {
            //  dispatch(fetchDeleteAllViewedNotificationsFailure(err));
            //}
            dispatch(fetchDeleteAllViewedNotificationsSuccess());
            console.log('Test delete all ok (wait this from backend)');
            return [2 /*return*/];
        });
    }); };
};
export var deleteOneNotification = function (id) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(fetchDeleteOneNotificationBegin());
            try {
                fetch("/api/v1/notification/delete/".concat(id), {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then(function (resp) {
                    if (resp.status == 200) {
                        dispatch(fetchDeleteOneNotificationSuccess());
                    }
                    return resp.json();
                });
            }
            catch (err) {
                dispatch(fetchDeleteOneNotificationFailure(err));
            }
            return [2 /*return*/];
        });
    }); };
};
