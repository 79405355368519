export var PatientsInitialState = {
    id: 0,
    expertIds: [],
    companyIds: [],
    consultantId: 0,
    lastName: '',
    firstName: '',
    secondName: '',
    birthday: '',
    avatar: '',
    email: '',
    address: '',
    gender: '',
    phone: '',
    messagesUrl: '',
    healthDynamicUrl: '',
    dataArchiveUrl: '',
    screaningUrl: '',
    tags: [],
    diseases: [],
    from: null,
    size: null,
    query: null,
    sort: null,
};
export var initialState = {
    patients: [PatientsInitialState],
    loading: false,
    error: null,
};
