var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AccessesActionType } from './action';
import { initialState } from './state';
export var accessesReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AccessesActionType.SET_DATA_FALSE:
            return __assign(__assign({}, state), { isAccessAdded: false, isAccessDeleted: false, isAccessEdited: false, loading: false, error: null });
        case AccessesActionType.GET_ACCESSES_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null });
        case AccessesActionType.GET_ACCESSES_SUCCESS:
            return __assign(__assign({}, state), { loading: false, accesses: action.payload });
        case AccessesActionType.GET_ACCESSES_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload });
        case AccessesActionType.ADD_ACCESS_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null, isAccessAdded: false });
        case AccessesActionType.ADD_ACCESS_SUCCESS:
            return __assign(__assign({}, state), { loading: false, isAccessAdded: true });
        case AccessesActionType.ADD_ACCESS_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload, isAccessAdded: false });
        case AccessesActionType.DELETE_ACCESS_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null, isAccessDeleted: false });
        case AccessesActionType.DELETE_ACCESS_SUCCESS:
            return __assign(__assign({}, state), { loading: false, isAccessDeleted: true });
        case AccessesActionType.DELETE_ACCESS_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload, isAccessDeleted: false });
        case AccessesActionType.EDIT_ACCESS_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null, isAccessEdited: false });
        case AccessesActionType.EDIT_ACCESS_SUCCESS:
            return __assign(__assign({}, state), { loading: false, isAccessEdited: true });
        case AccessesActionType.EDIT_ACCESS_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload, isAccessEdited: false });
        case AccessesActionType.GET_EXPERTS_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null });
        case AccessesActionType.GET_EXPERTS_SUCCESS:
            return __assign(__assign({}, state), { loading: false, experts: action.payload });
        case AccessesActionType.GET_EXPERTS_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload });
        default:
            return state;
    }
};
