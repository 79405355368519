export var RecordsInitialState = {
    id: 0,
    text: '',
    created_at: '',
    title: '',
    attachments: [],
    category: '',
    seen: false,
    is_deleted: false,
};
export var initialState = {
    records: [RecordsInitialState],
    isRecordCreated: false,
    isRecordDeleted: false,
    isRecordEdited: false,
    loading: false,
    error: null,
};
