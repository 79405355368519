var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotification } from '../../../hook/misc/use-notification';
import { isDesktop } from '../../../util/device';
import { ConnectedNotificationList } from '../../misc/ConnectedNotificationList/ConnectedNotificationList';
import { ConnectedMobileMenu } from '../../nav/Menu/MobileMenu/ConnectedMobileMenu';
import { HeaderCabinet } from '../../nav/HeaderCabinet/HeaderCabinet';
import { Breadcrumb } from '../../nav/Breadcrumbs/Breadcrumb';
import { Box, useMediaQuery } from '@mui/material';
import { Container } from '@mui/material';
import { Header } from '../../../../app/moi/components/Header';
import { TabletHeader } from '../../../../app/moi/components/Header/TabletHeader';
import Footer from '../../../../app/moi/components/Footer/Footer';
import { useLocation } from 'react-router-dom';
import { SideBarMenuDesktop } from '../../nav/Menu/SideBarDesktop/SideBarMenuDesktop';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useRequestState } from '../../../hook/misc/use-request-state';
import { getPersonalInformation } from '../../../../app/moi/store/user/personalInfo/factory';
import { getMedicalInformation } from '../../../../app/moi/store/user/medicalInfo/medicalInfo';
import { getAddressInformation } from '../../../../app/moi/store/user/addressInfo/factory';
import { CabinetSelector } from '../../../../app/moi/store/cabinet/selector';
import { IsExpertSelector, IsPatientSelector, RoleSelector, } from '../../../../app/moi/store/user/personalInfo/selector';
import { setCabinetType } from '../../../../app/moi/store/cabinet/factory';
import { NotificationsDeletedSelector, NotificationsMarkedSelector, } from '../../../../app/moi/store/notifications/selector';
import { getAllNotifications } from '../../../../app/moi/store/notifications/asyncActions';
import { getWorkInformation } from '../../../../app/moi/store/user/workInfo/factory';
import { getCategories } from '../../../../app/moi/store/categories/factory';
import { getAdditionalInformation } from '../../../../app/moi/store/user/additionalInfo/factory';
import { getAccesses } from '../../../../app/moi/store/data-archive/accesses/asyncActions';
import { RecordsFolderCreatedSelector, RecordsFolderDeletedSelector, RecordsFolderEditedSelector, } from '../../../../app/moi/store/data-archive/my-records/records-folders/selector';
import { getRecordsFolders } from '../../../../app/moi/store/data-archive/my-records/records-folders/asyncActions';
import { getRecordsCategories } from '../../../../app/moi/store/data-archive/my-records/records-categories-async/asyncActions';
import { getDeclarationInformation } from '../../../../app/moi/store/user/declarationInfo/factory';
import { IsDeclarationInfoChangedSelector } from '../../../../app/moi/store/user/declarationInfo/selector';
export var BasePage = function (props) {
    var action = useRequestState().action;
    var dispatch = useDispatch();
    var children = props.children;
    var errorNotification = useNotification().errorNotification;
    var showDesktopMenu = isDesktop(props.viewportWidth);
    var matches = useMediaQuery('(max-width:1700px)');
    var location = useLocation();
    var isCabinet = location.pathname.includes('/cabinet') || location.pathname.includes('/e-health/');
    var isHideHeaderAndFooter = location.pathname.includes('/uk/register');
    var cabinetType = useSelector(CabinetSelector);
    var isExpert = useSelector(IsExpertSelector);
    var isPatient = useSelector(IsPatientSelector);
    var role = useSelector(RoleSelector);
    var isMarked = useSelector(NotificationsMarkedSelector);
    var isDeleted = useSelector(NotificationsDeletedSelector);
    var isFolderCreated = useSelector(RecordsFolderCreatedSelector);
    var isFolderDeleted = useSelector(RecordsFolderDeletedSelector);
    var isFolderEdited = useSelector(RecordsFolderEditedSelector);
    var isDeclarationInfoChanged = useSelector(IsDeclarationInfoChangedSelector);
    var hideChat = document.querySelectorAll('.chat-frame-wrapper');
    useEffect(function () {
        if (hideChat[0]) {
            if (location.pathname.includes('/uk/cabinet/messages') || location.pathname.includes('/uk/cabinet/dialog')) {
                hideChat[0].style.display = 'block';
            }
            else {
                hideChat[0].style.display = 'none';
            }
        }
    }, [hideChat, location]);
    var t = useTranslation().t;
    useEffect(function () {
        if (!props.error) {
            return;
        }
        errorNotification(t('documents.error'), t('eHealth.reloadAndRetry'));
        console.error(props.error);
        props.clearError();
    }, [props.error]);
    useEffect(function () {
        dispatch(getRecordsFolders());
    }, [dispatch, isFolderCreated, isFolderDeleted, isFolderEdited]);
    useEffect(function () {
        action(function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                batch(function () {
                    dispatch(getPersonalInformation());
                    dispatch(getMedicalInformation());
                    dispatch(getAddressInformation());
                    dispatch(getWorkInformation());
                    dispatch(getAdditionalInformation());
                    dispatch(getDeclarationInformation());
                    dispatch(getCategories());
                    dispatch(getAccesses());
                    dispatch(getRecordsCategories());
                });
                return [2 /*return*/];
            });
        }); });
    }, [dispatch, isDeclarationInfoChanged]);
    useEffect(function () {
        dispatch(getAllNotifications());
    }, [dispatch, isMarked, isDeleted]);
    useEffect(function () {
        if ((cabinetType === null || cabinetType === void 0 ? void 0 : cabinetType.length) === 0 || cabinetType === "''") {
            switch (true) {
                case role.includes('clinic'):
                    dispatch(setCabinetType('clinic'));
                    break;
                case role.includes('doctor'):
                    dispatch(setCabinetType('doctor'));
                    break;
                case role.includes('patient'):
                    dispatch(setCabinetType('patient'));
                    break;
            }
        }
    }, [dispatch, isExpert, isPatient, cabinetType]);
    var Sidebar = function () { return (React.createElement("div", { className: "cabinet-sidebar" },
        React.createElement(SideBarMenuDesktop, null))); };
    return (React.createElement(React.Fragment, null,
        isCabinet && React.createElement(Sidebar, null),
        React.createElement(Box, { component: "main", sx: { padding: "0px ".concat(isCabinet ? '20px' : '0px'), flexGrow: 1, overflow: 'hidden' } },
            React.createElement(Container, { disableGutters: true, maxWidth: false },
                isCabinet && React.createElement(HeaderCabinet, null),
                isCabinet && React.createElement(Breadcrumb, null),
                !isCabinet && !isHideHeaderAndFooter && (matches ? React.createElement(TabletHeader, null) : React.createElement(Header, null)),
                React.createElement("div", { className: "cabinet-content" }, children),
                !isCabinet && !isHideHeaderAndFooter && React.createElement(Footer, null))),
        !showDesktopMenu && React.createElement(ConnectedMobileMenu, { title: t('profile.menu') }),
        React.createElement(ConnectedNotificationList, null)));
};
