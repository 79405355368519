export var ChatModalActionTypes;
(function (ChatModalActionTypes) {
    ChatModalActionTypes[ChatModalActionTypes["ShowCreateChatModal"] = 0] = "ShowCreateChatModal";
    ChatModalActionTypes[ChatModalActionTypes["HideCreateChatModal"] = 1] = "HideCreateChatModal";
})(ChatModalActionTypes || (ChatModalActionTypes = {}));
export function showCreateChatModal() {
    return {
        type: ChatModalActionTypes.ShowCreateChatModal,
    };
}
export function hideCreateChatModal() {
    return {
        type: ChatModalActionTypes.HideCreateChatModal,
    };
}
