var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MedicalInfoActionType } from './constants';
var medicalInitialState = {
    allergy: '',
    bad_habits: '',
    blood_group: '',
    chronic_diseases: '',
    declaration: '',
    donor_organs: '',
    emergency_contact: '',
    height: '',
    id: 0,
    medicine: '',
    notes: '',
    updated_at: 0,
    weight: '',
};
var initialState = {
    medical: medicalInitialState,
    isDataChanged: false,
    loading: false,
    error: null,
};
export var medicalReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case MedicalInfoActionType.SET_DATA_FALSE:
            return __assign(__assign({}, state), { isDataChanged: false, loading: false, error: null });
        case MedicalInfoActionType.GET_MEDICAL_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null });
        case MedicalInfoActionType.GET_MEDICAL_SUCCESS:
            return __assign(__assign({}, state), { loading: false, medical: action.payload });
        case MedicalInfoActionType.GET_MEDICAL_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload });
        case MedicalInfoActionType.SET_MEDICAL_PENDING:
            return __assign(__assign({}, state), { isDataChanged: false, loading: true, error: null });
        case MedicalInfoActionType.SET_MEDICAL_SUCCESS:
            return __assign(__assign({}, state), { isDataChanged: true, loading: false, medical: action.payload });
        case MedicalInfoActionType.SET_MEDICAL_ERROR:
            return __assign(__assign({}, state), { isDataChanged: false, loading: false, error: action.payload });
        default:
            return state;
    }
};
