export var ClinicScheduleModalActionTypes;
(function (ClinicScheduleModalActionTypes) {
    ClinicScheduleModalActionTypes[ClinicScheduleModalActionTypes["ShowClinicScheduleModal"] = 0] = "ShowClinicScheduleModal";
    ClinicScheduleModalActionTypes[ClinicScheduleModalActionTypes["HideClinicScheduleModal"] = 1] = "HideClinicScheduleModal";
})(ClinicScheduleModalActionTypes || (ClinicScheduleModalActionTypes = {}));
export function showClinicScheduleModal() {
    return {
        type: ClinicScheduleModalActionTypes.ShowClinicScheduleModal,
    };
}
export function hideClinicScheduleModal() {
    return {
        type: ClinicScheduleModalActionTypes.HideClinicScheduleModal,
    };
}
