var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RecordsActionType } from './action';
import { initialState } from './state';
export var recordsAsyncReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case RecordsActionType.SET_DATA_FALSE:
            return __assign(__assign({}, state), { isRecordDeleted: false, isRecordCreated: false, isRecordEdited: false, loading: false, error: null });
        case RecordsActionType.GET_RECORDS_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null });
        case RecordsActionType.GET_RECORDS_SUCCESS:
            return __assign(__assign({}, state), { loading: false, records: action.payload });
        case RecordsActionType.GET_RECORDS_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload });
        case RecordsActionType.ADD_RECORD_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null, isRecordCreated: false });
        case RecordsActionType.ADD_RECORD_SUCCESS:
            return __assign(__assign({}, state), { loading: false, isRecordCreated: true });
        case RecordsActionType.ADD_RECORD_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload, isRecordCreated: false });
        case RecordsActionType.DELETE_RECORD_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null, isRecordDeleted: false });
        case RecordsActionType.DELETE_RECORD_SUCCESS:
            return __assign(__assign({}, state), { loading: false, isRecordDeleted: true });
        case RecordsActionType.DELETE_RECORD_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload, isRecordDeleted: false });
        case RecordsActionType.EDIT_RECORD_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null, isRecordEdited: false });
        case RecordsActionType.EDIT_RECORD_SUCCESS:
            return __assign(__assign({}, state), { loading: false, isRecordEdited: true });
        case RecordsActionType.EDIT_RECORD_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload, isRecordEdited: false });
        default:
            return state;
    }
};
