export var MedicalInfoActionType;
(function (MedicalInfoActionType) {
    MedicalInfoActionType["GET_MEDICAL_PENDING"] = "GET_MEDICAL_PENDING";
    MedicalInfoActionType["GET_MEDICAL_SUCCESS"] = "GET_MEDICAL_SUCCESS";
    MedicalInfoActionType["GET_MEDICAL_ERROR"] = "GET_MEDICAL_ERROR";
    MedicalInfoActionType["SET_MEDICAL_PENDING"] = "SET_MEDICAL_PENDING";
    MedicalInfoActionType["SET_MEDICAL_SUCCESS"] = "SET_MEDICAL_SUCCESS";
    MedicalInfoActionType["SET_MEDICAL_ERROR"] = "SET_MEDICAL_ERROR";
    MedicalInfoActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(MedicalInfoActionType || (MedicalInfoActionType = {}));
