export var ClinicServiceActionType;
(function (ClinicServiceActionType) {
    ClinicServiceActionType["GET_CLINIC_SERVICES_PENDING"] = "GET_CLINIC_SERVICES_PENDING";
    ClinicServiceActionType["GET_CLINIC_SERVICES_SUCCESS"] = "GET_CLINIC_SERVICES_SUCCESS";
    ClinicServiceActionType["GET_CLINIC_SERVICES_ERROR"] = "GET_CLINIC_SERVICES_ERROR";
    ClinicServiceActionType["ADD_CLINIC_SERVICE_PENDING"] = "ADD_CLINIC_SERVICE_PENDING";
    ClinicServiceActionType["ADD_CLINIC_SERVICE_SUCCESS"] = "ADD_CLINIC_SERVICE_SUCCESS";
    ClinicServiceActionType["ADD_CLINIC_SERVICE_ERROR"] = "ADD_CLINIC_SERVICE_ERROR";
    ClinicServiceActionType["EDIT_CLINIC_SERVICE_PENDING"] = "EDIT_CLINIC_SERVICE_PENDING";
    ClinicServiceActionType["EDIT_CLINIC_SERVICE_SUCCESS"] = "EDIT_CLINIC_SERVICE_SUCCESS";
    ClinicServiceActionType["EDIT_CLINIC_SERVICE_ERROR"] = "EDIT_CLINIC_SERVICE_ERROR";
    ClinicServiceActionType["DELETE_CLINIC_SERVICE_PENDING"] = "DELETE_CLINIC_SERVICE_PENDING";
    ClinicServiceActionType["DELETE_CLINIC_SERVICE_SUCCESS"] = "DELETE_CLINIC_SERVICE_SUCCESS";
    ClinicServiceActionType["DELETE_CLINIC_SERVICE_ERROR"] = "DELETE_CLINIC_SERVICE_ERROR";
    ClinicServiceActionType["SET_CURRENT_PAGE_CLINIC_SERVICE"] = "SET_CURRENT_PAGE_CLINIC_SERVICE";
})(ClinicServiceActionType || (ClinicServiceActionType = {}));
