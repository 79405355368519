export var BookingServicesActionType;
(function (BookingServicesActionType) {
    BookingServicesActionType["GET_SERVICES_PENDING"] = "GET_SERVICES_PENDING";
    BookingServicesActionType["GET_SERVICES_SUCCESS"] = "GET_SERVICES_SUCCESS";
    BookingServicesActionType["GET_SERVICES_ERROR"] = "GET_SERVICES_ERROR";
    BookingServicesActionType["ADD_SERVICE_PENDING"] = "ADD_SERVICE_PENDING";
    BookingServicesActionType["ADD_SERVICE_SUCCESS"] = "ADD_SERVICE_SUCCESS";
    BookingServicesActionType["ADD_SERVICE_ERROR"] = "ADD_SERVICE_ERROR";
    BookingServicesActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(BookingServicesActionType || (BookingServicesActionType = {}));
