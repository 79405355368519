import { combineReducers } from 'redux';
import { expertsModalReducer } from './experts/reducers';
import { chatModalReducer } from './chat/reducers';
import { serviceModalReducer } from './service/reducers';
import { galleryModalReducer } from './gallery/reducers';
import { clinicScheduleModalReducer } from './clinicSchedule/reducers';
export var modalReducer = combineReducers({
    experts: expertsModalReducer,
    chat: chatModalReducer,
    service: serviceModalReducer,
    gallery: galleryModalReducer,
    clinicSchedule: clinicScheduleModalReducer,
});
