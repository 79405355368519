var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AdditionalInfoActionType } from './constants';
import { initialState } from './state';
export var additionalReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AdditionalInfoActionType.SET_DATA_FALSE:
            return __assign(__assign({}, state), { isDataChanged: false, loading: false, error: null });
        case AdditionalInfoActionType.GET_ADDITIONAL_INFO_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null });
        case AdditionalInfoActionType.GET_ADDITIONAL_INFO_SUCCESS:
            return __assign(__assign({}, state), { loading: false, additional: action.payload });
        case AdditionalInfoActionType.GET_ADDITIONAL_INFO_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload });
        case AdditionalInfoActionType.SET_ADDITIONAL_INFO_PENDING:
            return __assign(__assign({}, state), { isDataChanged: false, loading: true, error: null });
        case AdditionalInfoActionType.SET_ADDITIONAL_INFO_SUCCESS:
            return __assign(__assign({}, state), { isDataChanged: true, loading: false });
        case AdditionalInfoActionType.SET_ADDITIONAL_INFO_ERROR:
            return __assign(__assign({}, state), { isDataChanged: false, loading: false, error: action.payload });
        default:
            return state;
    }
};
