export var WorkInfoActionType;
(function (WorkInfoActionType) {
    WorkInfoActionType["GET_WORK_INFO_PENDING"] = "GET_WORK_INFO_PENDING";
    WorkInfoActionType["GET_WORK_INFO_SUCCESS"] = "GET_WORK_INFO_SUCCESS";
    WorkInfoActionType["GET_WORK_INFO_ERROR"] = "GET_WORK_INFO_ERROR";
    WorkInfoActionType["SET_WORK_INFO_PENDING"] = "SET_WORK_INFO_PENDING";
    WorkInfoActionType["SET_WORK_INFO_SUCCESS"] = "SET_WORK_INFO_SUCCESS";
    WorkInfoActionType["SET_WORK_INFO_ERROR"] = "SET_WORK_INFO_ERROR";
    WorkInfoActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(WorkInfoActionType || (WorkInfoActionType = {}));
