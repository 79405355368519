export var MoiRoutes;
(function (MoiRoutes) {
    MoiRoutes["MEDICAL_ARCHIVE"] = "MEDICAL_ARCHIVE";
    MoiRoutes["SCREENING_AND_MONITORING"] = "SCREENING_AND_MONITORING";
    MoiRoutes["USER_PROFILE"] = "USER_PROFILE";
    MoiRoutes["HEALTH_DYNAMIC"] = "HEALTH_DYNAMIC";
    MoiRoutes["HEALTH_DYNAMIC_BLOODY_PRESSURE"] = "HEALTH_DYNAMIC_BLOODY_PRESSURE";
    MoiRoutes["HEALTH_DYNAMIC_PHYSICAL_PARAMETERS"] = "HEALTH_DYNAMIC_PHYSICAL_PARAMETERS";
    MoiRoutes["HEALTH_DYNAMIC_BLOOD_ANALYSIS"] = "HEALTH_DYNAMIC_BLOOD_ANALYSIS";
    MoiRoutes["HEALTH_DYNAMIC_URINE_ANALYSIS"] = "HEALTH_DYNAMIC_URINE_ANALYSIS";
    MoiRoutes["HEALTH_DYNAMIC_SUBJECTIVE_STATE"] = "HEALTH_DYNAMIC_SUBJECTIVE_STATE";
    MoiRoutes["HEALTH_DYNAMIC_TEMPERATURE"] = "HEALTH_DYNAMIC_TEMPERATURE";
    MoiRoutes["HEALTH_DYNAMIC_BREATHING_RATE"] = "HEALTH_DYNAMIC_BREATHING_RATE";
    MoiRoutes["USER_NOTIFICATIONS"] = "USER_NOTIFICATIONS";
    MoiRoutes["BOOKING_VIDEO_CALL"] = "BOOKING_VIDEO_CALL";
    MoiRoutes["CHAT_MESSAGE_TEMPLATE"] = "CHAT_MESSAGE_TEMPLATE";
    MoiRoutes["CHAT_EMAIL_MESSAGE_TEMPLATE"] = "CHAT_EMAIL_MESSAGE_TEMPLATE";
    MoiRoutes["CLIENTS"] = "CLIENTS";
    MoiRoutes["COMPANY_CLIENTS"] = "COMPANY_CLIENTS";
    MoiRoutes["COMPANY_SCHEDULE"] = "COMPANY_SCHEDULE";
    MoiRoutes["COMPANY_EXPERTS"] = "COMPANY_EXPERTS";
    MoiRoutes["COMPANY_REVIEWS"] = "COMPANY_REVIEWS";
    MoiRoutes["COMPANY_GALLERY"] = "COMPANY_GALLERY";
    MoiRoutes["COMPANY_PATIENT_ENTRIES"] = "COMPANY_PATIENT_ENTRIES";
    MoiRoutes["COMPANY_EXPERT_LIST"] = "COMPANY_EXPERT_LIST";
    MoiRoutes["COMPANY_EXPERT_INVITE"] = "COMPANY_EXPERT_INVITE";
    MoiRoutes["COMPANY_EXPERT_CREATE_REPORT"] = "COMPANY_EXPERT_CREATE_REPORT";
    MoiRoutes["COMPANY_NEWS"] = "COMPANY_NEWS";
    MoiRoutes["COMPANY_CABINET"] = "COMPANY_CABINET";
    MoiRoutes["COMPANY_PROFILE_CREATE"] = "COMPANY_PROFILE_CREATE";
    MoiRoutes["COMPANY_E_HEALTH_PROFILE"] = "COMPANY_E_HEALTH_PROFILE";
    MoiRoutes["COMPANY_PROFILE_EDIT"] = "COMPANY_PROFILE_EDIT";
    MoiRoutes["COMPANY_EXPERT_SCHEDULE"] = "COMPANY_EXPERT_SCHEDULE";
    MoiRoutes["COMPANY_EXPERT_SERVICES"] = "COMPANY_EXPERT_SERVICES";
    MoiRoutes["COMPANY_SERVICES"] = "COMPANY_SERVICES";
    MoiRoutes["COMPANY_SERVICES_CREATE"] = "COMPANY_SERVICES_CREATE";
    MoiRoutes["COMPANY_SERVICES_EDIT"] = "COMPANY_SERVICES_EDIT";
    MoiRoutes["COMPANY_NEWS_ARTICLE"] = "COMPANY_NEWS_ARTICLE";
    MoiRoutes["COMPANY_EXPERTS_SCHEDULE"] = "COMPANY_EXPERTS_SCHEDULE";
    MoiRoutes["REFERENCE"] = "REFERENCE";
    MoiRoutes["MY_ANALYSE"] = "MY_ANALYSE";
    MoiRoutes["MY_BOOKED"] = "MY_BOOKED";
    MoiRoutes["CHARGES"] = "CHARGES";
    MoiRoutes["SEARCH"] = "SEARCH";
    MoiRoutes["REFERENCE_CREATE"] = "REFERENCE_CREATE";
    MoiRoutes["NOT_FOUND"] = "NOT_FOUND";
    MoiRoutes["REFERRAL"] = "REFERRAL";
    MoiRoutes["BOOKED_SERVICE"] = "BOOKED_SERVICE";
    MoiRoutes["EXPERT_BOOKED_SERVICES"] = "EXPERT_BOOKED_SERVICES";
    MoiRoutes["EXPERT_CLIENTS"] = "EXPERT_CLIENTS";
    MoiRoutes["EXPERT_SCHEDULE"] = "EXPERT_SCHEDULE";
    MoiRoutes["EXPERT_SERVICES"] = "EXPERT_SERVICES";
    MoiRoutes["HOME_PAGE"] = "HOME_PAGE";
    MoiRoutes["MESSAGES"] = "MESSAGES";
    MoiRoutes["COMPANY_EHEALTH"] = "COMPANY_EHEALTH";
})(MoiRoutes || (MoiRoutes = {}));
