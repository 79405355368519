export var ServiceActionType;
(function (ServiceActionType) {
    ServiceActionType["GET_SERVICES_PENDING"] = "GET_SERVICES_PENDING";
    ServiceActionType["GET_SERVICES_SUCCESS"] = "GET_SERVICES_SUCCESS";
    ServiceActionType["GET_SERVICES_ERROR"] = "GET_SERVICES_ERROR";
    ServiceActionType["ADD_SERVICE_PENDING"] = "ADD_SERVICE_PENDING";
    ServiceActionType["ADD_SERVICE_SUCCESS"] = "ADD_SERVICE_SUCCESS";
    ServiceActionType["ADD_SERVICE_ERROR"] = "ADD_SERVICE_ERROR";
    ServiceActionType["DELETE_SERVICE_PENDING"] = "DELETE_SERVICE_PENDING";
    ServiceActionType["DELETE_SERVICE_SUCCESS"] = "DELETE_SERVICE_SUCCESS";
    ServiceActionType["DELETE_SERVICE_ERROR"] = "DELETE_SERVICE_ERROR";
    ServiceActionType["EDIT_SERVICE_PENDING"] = "EDIT_SERVICE_PENDING";
    ServiceActionType["EDIT_SERVICE_SUCCESS"] = "EDIT_SERVICE_SUCCESS";
    ServiceActionType["EDIT_SERVICE_ERROR"] = "EDIT_SERVICE_ERROR";
    ServiceActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(ServiceActionType || (ServiceActionType = {}));
