var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BookingsActionType } from './action';
import { initialState } from './state';
export var profileBookingsReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case BookingsActionType.SET_DATA_FALSE:
            return __assign(__assign({}, state), { isBookingDeleted: false, isBookingCreated: false, isBookingEdited: false, loading: false, error: null });
        case BookingsActionType.GET_BOOKINGS_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null });
        case BookingsActionType.GET_BOOKINGS_SUCCESS:
            return __assign(__assign({}, state), { loading: false, bookings: action.payload });
        case BookingsActionType.GET_BOOKINGS_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload });
        case BookingsActionType.ADD_BOOKING_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null, isBookingCreated: false });
        case BookingsActionType.ADD_BOOKING_SUCCESS:
            return __assign(__assign({}, state), { loading: false, isBookingCreated: true });
        case BookingsActionType.ADD_BOOKING_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload, isBookingCreated: false });
        case BookingsActionType.DELETE_BOOKING_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null, isBookingDeleted: false });
        case BookingsActionType.DELETE_BOOKING_SUCCESS:
            return __assign(__assign({}, state), { loading: false, isBookingDeleted: true });
        case BookingsActionType.DELETE_BOOKING_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload, isBookingDeleted: false });
        case BookingsActionType.EDIT_BOOKING_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null, isBookingEdited: false });
        case BookingsActionType.EDIT_BOOKING_SUCCESS:
            return __assign(__assign({}, state), { loading: false, isBookingEdited: true });
        case BookingsActionType.EDIT_BOOKING_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload, isBookingEdited: false });
        default:
            return state;
    }
};
