export var BookingsActionType;
(function (BookingsActionType) {
    BookingsActionType["GET_BOOKINGS_PENDING"] = "GET_BOOKINGS_PENDING";
    BookingsActionType["GET_BOOKINGS_SUCCESS"] = "GET_BOOKINGS_SUCCESS";
    BookingsActionType["GET_BOOKINGS_ERROR"] = "GET_BOOKINGS_ERROR";
    BookingsActionType["ADD_BOOKING_PENDING"] = "ADD_BOOKING_PENDING";
    BookingsActionType["ADD_BOOKING_SUCCESS"] = "ADD_BOOKING_SUCCESS";
    BookingsActionType["ADD_BOOKING_ERROR"] = "ADD_BOOKING_ERROR";
    BookingsActionType["DELETE_BOOKING_PENDING"] = "DELETE_BOOKING_PENDING";
    BookingsActionType["DELETE_BOOKING_SUCCESS"] = "DELETE_BOOKING_SUCCESS";
    BookingsActionType["DELETE_BOOKING_ERROR"] = "DELETE_BOOKING_ERROR";
    BookingsActionType["EDIT_BOOKING_PENDING"] = "EDIT_BOOKING_PENDING";
    BookingsActionType["EDIT_BOOKING_SUCCESS"] = "EDIT_BOOKING_SUCCESS";
    BookingsActionType["EDIT_BOOKING_ERROR"] = "EDIT_BOOKING_ERROR";
    BookingsActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(BookingsActionType || (BookingsActionType = {}));
