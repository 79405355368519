var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Box, List, ListItem, Typography } from '@mui/material';
import { useStyles } from './TabletHeader.jss';
import LogoGradientIconSvg from '../../assets/svg_components/LogoIconSvg/LogoGradientIconSvg';
import LogoTitleIconSvg from '../../assets/svg_components/LogoIconSvg/LogoTitleIconSvg';
import ButtonAction from './ButtonAction/ButtonAction';
import SearchIconSizedSvg from '../../assets/svg_components/SearchIconSizedSvg';
import { Swicher } from './LanguageSwitcher';
import PersonIconSvg from '../../assets/svg_components/PersonIconSvg';
import MenuOpenedIconSvg from '../../assets/svg_components/MenuOpenedIconSvg';
import MenuClosedIconSvg from '../../assets/svg_components/MenuClosedIconSvg';
import PhoneIconSvg from '../../assets/svg_components/PhoneIconSvg';
import ModalComponent from './Modal/ModalComponent';
import ButtonActionCabinet from './ButtonAction/ButtonActionCabinet';
import { Link } from 'react-router-dom';
export var TabletHeader = function (props) {
    var classes = useStyles();
    var _a = __read(useState(false), 2), showMenu = _a[0], setShowMenu = _a[1];
    var _b = __read(useState(false), 2), open = _b[0], setOpen = _b[1];
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () { return setOpen(false); };
    var _c = __read(useState(false), 2), isLogin = _c[0], setIsLogin = _c[1];
    useEffect(function () {
        if (open) {
            setShowMenu(false);
        }
    }, [open]);
    return (React.createElement(Box, { className: classNames(classes.wrapper) },
        React.createElement(Link, { to: "/" },
            React.createElement(Box, null,
                React.createElement(LogoGradientIconSvg, null),
                React.createElement(LogoTitleIconSvg, null))),
        React.createElement(Box, { className: classNames(classes.rightBlock) },
            React.createElement(ButtonAction, { width: 50, height: 40, borderRadius: 25, backgroundColor: "#29F499", textColor: "#000000", icon: React.createElement(SearchIconSizedSvg, { classname: classNames(classes.buttonActionIcon) }), hideText: true, buttonTitle: "\u0417\u043D\u0430\u0439\u0442\u0438 \u043B\u0456\u043A\u0430\u0440\u044F", reactLink: true, buttonLink: "/uk/experts" }),
            React.createElement(Box, { className: classNames(classes.menuButton), onClick: function () { return setShowMenu(!showMenu); } }, showMenu ? React.createElement(MenuOpenedIconSvg, null) : React.createElement(MenuClosedIconSvg, null))),
        showMenu && (React.createElement(Box, { className: classNames(classes.bottomMenu) },
            React.createElement(Box, { className: classNames(classes.phoneBlock) },
                React.createElement(Box, { className: classNames(classes.phoneIcon) },
                    React.createElement(PhoneIconSvg, null)),
                React.createElement(Box, { className: classNames(classes.phoneWrapper) },
                    React.createElement(Typography, { className: classNames(classes.phoneTitle) }, "\u041F\u0456\u0434\u0442\u0440\u0438\u043C\u043A\u0430"),
                    React.createElement(Typography, { className: classNames(classes.phoneNumber) },
                        React.createElement("a", { href: "tel: +38 (093) 059 43 44" }, "+38 (093) 059 43 44")))),
            React.createElement(List, { className: classNames(classes.list) },
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/patient" }, "\u041F\u0430\u0446\u0456\u0454\u043D\u0442\u0430\u043C")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/doctor" }, "\u041B\u0456\u043A\u0430\u0440\u044F\u043C")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/clinics" }, "\u041A\u043B\u0456\u043D\u0456\u043A\u0430\u043C")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement(Link, { to: "/uk/news" }, "\u041D\u043E\u0432\u0438\u043D\u0438")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/faq" }, "\u0414\u043E\u0432\u0456\u0434\u043A\u0430")),
                React.createElement(ListItem, { className: classNames(classes.listItem) },
                    React.createElement("a", { href: "/uk/ehealth" }, "MIC MyHeal"))),
            React.createElement(Box, { className: classNames(classes.rightBlock) },
                React.createElement(Box, { sx: { marginRight: '10px' } },
                    React.createElement(Swicher, null)),
                React.createElement(ButtonActionCabinet, { width: 130, height: 50, borderRadius: 35, backgroundColor: "#1E1E1E", textColor: "#FFFFFF", icon: React.createElement(PersonIconSvg, { color: "white" }), buttonTitle: "\u041A\u0430\u0431\u0456\u043D\u0435\u0442", buttonLink: "".concat(window.location.origin, "/uk/cabinet/settings#personalData"), voidLink: handleOpen, isLogin: isLogin })))),
        React.createElement(ModalComponent, { open: open, handleClose: handleClose })));
};
