import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useStyles } from '../HeaderCabinetStyle';
import ArrowDownIconSvg from '../assets/ArrowDownIconSvg';
import ArrowUpIconSvg from '../assets/ArrowUpIconSvg';
export var CabinetProfile = function (_a) {
    var isOpen = _a.isOpen, handleClick = _a.handleClick, id = _a.id;
    var classes = useStyles();
    return (React.createElement(Button, { "aria-describedby": id, className: classes.CabinetWrapper, onClick: handleClick, endIcon: isOpen ? React.createElement(ArrowUpIconSvg, null) : React.createElement(ArrowDownIconSvg, null) },
        React.createElement(Box, { sx: { marginRight: '10px', borderRadius: '50%' }, component: "img", src: require("../assets/img.jpg") }),
        React.createElement(Typography, { className: classes.customTypography }, "\u041A\u0430\u0431\u0456\u043D\u0435\u0442")));
};
