export var ReferencesInitialState = {
    id: 0,
    expertId: 0,
    expertName: '',
    clientId: 0,
    clientName: '',
    form: '',
    payload: [],
    createdAt: '',
};
export var initialState = {
    references: [ReferencesInitialState],
    totalCount: 0,
    totalPages: 0,
    page: 1,
    isReferenceCreated: false,
    isReferenceDeleted: false,
    loading: false,
    error: null,
};
