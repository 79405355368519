var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ClinicServiceActionType } from './constants';
var initialState = {
    services: [],
    total_count: 0,
    total_pages: 0,
    current_page: 1,
    isServiceCreated: false,
    isServiceEdited: false,
    isServiceDeleted: false,
    loading: false,
    error: null,
};
export var clinicServiceReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ClinicServiceActionType.GET_CLINIC_SERVICES_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null });
        case ClinicServiceActionType.GET_CLINIC_SERVICES_SUCCESS:
            return __assign(__assign({}, state), { loading: false, services: action.payload.services, total_count: action.payload.total_count, total_pages: action.payload.total_pages });
        case ClinicServiceActionType.GET_CLINIC_SERVICES_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload });
        case ClinicServiceActionType.ADD_CLINIC_SERVICE_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null, isServiceCreated: false });
        case ClinicServiceActionType.ADD_CLINIC_SERVICE_SUCCESS:
            return __assign(__assign({}, state), { loading: false, isServiceCreated: true });
        case ClinicServiceActionType.ADD_CLINIC_SERVICE_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload, isServiceCreated: false });
        case ClinicServiceActionType.EDIT_CLINIC_SERVICE_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null, isServiceEdited: false });
        case ClinicServiceActionType.EDIT_CLINIC_SERVICE_SUCCESS:
            return __assign(__assign({}, state), { loading: false, isServiceEdited: true });
        case ClinicServiceActionType.EDIT_CLINIC_SERVICE_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload, isServiceEdited: false });
        case ClinicServiceActionType.DELETE_CLINIC_SERVICE_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null, isServiceDeleted: false });
        case ClinicServiceActionType.DELETE_CLINIC_SERVICE_SUCCESS:
            return __assign(__assign({}, state), { loading: false, isServiceDeleted: true });
        case ClinicServiceActionType.DELETE_CLINIC_SERVICE_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload, isServiceDeleted: false });
        case ClinicServiceActionType.SET_CURRENT_PAGE_CLINIC_SERVICE:
            return __assign(__assign({}, state), { current_page: action.payload });
        default:
            return state;
    }
};
