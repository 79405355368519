var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Breadcrumbs } from '@material-ui/core';
import { HomeIcon } from './HomeIcon';
import { Box, Link, Stack, Typography } from '@mui/material';
import { useStyles } from './BreadcrumbStyle';
import { SeparatorIcon } from './SeparatorIcon';
import { useLocation, Link as RouterLink } from 'react-router-dom';
var breadcrumbNameMap = {
    '/uk/cabinet/data_archive': 'Медичний архів',
    '/uk/cabinet/screaning-screaning': 'Скринінг та моніторинг',
    '/uk/cabinet/my-health-dynamic': 'Динаміка здоровʼя',
    '/uk/cabinet/my-health-dynamic/health-dynamics/101': 'Кровʼяний тиск',
    '/uk/cabinet/my-health-dynamic/health-dynamics/102': 'Фізичні параметри',
    '/uk/cabinet/my-health-dynamic/health-dynamics/103': 'Аналіз крові',
    '/uk/cabinet/my-health-dynamic/health-dynamics/104': 'Аналіз сечі',
    '/uk/cabinet/my-health-dynamic/health-dynamics/105': "Суб'єктивний стан",
    '/uk/cabinet/my-health-dynamic/health-dynamics/106': 'Температура',
    '/uk/cabinet/my-health-dynamic/health-dynamics/107': 'Частота дихання',
    '/uk/cabinet/booked-services': 'Записи пацієнтів',
    '/uk/cabinet/my-booked-services': 'Записи до лікарів',
    '/uk/cabinet/my-analyse': 'Направлення',
    '/uk/cabinet/reference': 'Довідки',
    '/uk/cabinet/charges': 'Моя родина',
    '/uk/cabinet/messages': 'Повідомлення',
    '/uk/cabinet/notification': 'Сповіщення',
    '/uk/cabinet/schedule': 'Графік прийомів',
    '/uk/cabinet/clients': 'Пацієнти',
    '/uk/cabinet/service': 'Послуги',
    '/uk/cabinet/referral': 'Направлення',
    '/uk/cabinet/settings': 'Мій профіль',
    '/uk/e-health': 'e-health',
    '/uk/cabinet/company/patient-entries': 'Записи пацієнтів',
    '/uk/cabinet/company/services': 'Послуги медзакладу',
    '/uk/cabinet/company/reviews': 'Відгуки',
    '/uk/cabinet/company/gallery': 'Фотогалерея',
    '/uk/cabinet/company/experts-new': 'Працівники закладу',
    '/uk/cabinet/company/experts-schedule': 'Графік роботи медзакладу',
    '/uk/cabinet/company/news': 'Новини',
    '/uk/cabinet/company/profile/new': 'Кабінет закладу',
    '/uk/cabinet/company/legal-entity': 'Медзаклад в eHealth',
    '/uk/cabinet/service-one': 'Записи',
};
function LinkRouter(props) {
    return React.createElement(Link, __assign({}, props, { component: RouterLink }));
}
export var Breadcrumb = function () {
    var classes = useStyles();
    var location = useLocation();
    var pathnames = location.pathname.split('/').filter(function (x) { return x; });
    return (React.createElement(Box, { sx: { marginTop: '20px', marginBottom: '20px' } },
        React.createElement(Breadcrumbs, { "aria-label": "breadcrumb", separator: React.createElement(SeparatorIcon, null) },
            React.createElement(Stack, { justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: '4px' },
                React.createElement(HomeIcon, null),
                React.createElement(Link, { underline: "none", key: "1", className: classes.customTypography, href: "/uk/cabinet/settings" }, "\u041A\u0430\u0431\u0456\u043D\u0435\u0442")),
            pathnames.map(function (value, index) {
                var last = index === pathnames.length - 1;
                var to = "/".concat(pathnames.slice(0, index + 1).join('/'));
                return last ? (React.createElement(Typography, { key: to, className: classes.customTypographyNow }, breadcrumbNameMap[to])) : breadcrumbNameMap[to] ? (React.createElement(LinkRouter, { underline: "hover", color: "inherit", to: to, key: to },
                    React.createElement(Typography, { className: classes.customTypography }, breadcrumbNameMap[to]))) : ('');
            }))));
};
//export default function RouterBreadcrumbs() {
//  const [open, setOpen] = React.useState(true);
//  const handleClick = () => {
//    setOpen(prevOpen => !prevOpen);
//  };
//  return (
//    <MemoryRouter initialEntries={['/uk/cabinet']} initialIndex={0}>
//      <Box sx={{ display: 'flex', flexDirection: 'column', width: 360 }}>
//        {/*<Switch>
//          <Route path="*" component={<Page />} />
//        </Switch>*/}
//        <Box sx={{ bgcolor: 'background.paper', mt: 1 }} component="nav" aria-label="mailbox folders">
//          <List>
//            <ListItemLink to="/inbox" open={open} onClick={handleClick} />
//            <Collapse component="li" in={open} timeout="auto" unmountOnExit>
//              <List disablePadding>
//                <ListItemLink sx={{ pl: 4 }} to="/inbox/important" />
//              </List>
//            </Collapse>
//            <ListItemLink to="/trash" />
//            <ListItemLink to="/spam" />
//          </List>
//        </Box>
//      </Box>
//    </MemoryRouter>
//  );
//}
//return (
//  <Box sx={{ marginTop: '20px', marginBottom: '20px' }}>
//    <Breadcrumbs separator={<SeparatorIcon />} aria-label="breadcrumb">
//      <Stack justifyContent={'center'} alignItems={'center'} flexDirection={'row'} gap={'4px'}>
//        <HomeIcon />
//        <Link underline="none" key="1" className={classes.customTypography} href="/uk/cabinet/settings">
//          Кабінет
//        </Link>
//      </Stack>
//      {/*<Typography key="3" className={classes.customTypographyNow}>
//        {pageTitle}
//      </Typography>*/}
//      {pathnames.map((value, index) => {
//        const last = index === pathnames.length - 1;
//        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
//        return last ? (
//          <Typography key={to} sx={{ color: 'text.primary' }}>
//            {breadcrumbNameMap[to]}
//          </Typography>
//        ) : (
//          <LinkRouter underline="hover" color="inherit" to={to} key={to}>
//            {breadcrumbNameMap[to]}
//          </LinkRouter>
//        );
//      })}
//    </Breadcrumbs>
//  </Box>
//);
