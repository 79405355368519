export var AddressInfoActionType;
(function (AddressInfoActionType) {
    AddressInfoActionType["GET_ADDRESS_PENDING"] = "GET_ADDRESS_PENDING";
    AddressInfoActionType["GET_ADDRESS_SUCCESS"] = "GET_ADDRESS_SUCCESS";
    AddressInfoActionType["GET_ADDRESS_ERROR"] = "GET_ADDRESS_ERROR";
    AddressInfoActionType["SET_ADDRESS_PENDING"] = "SET_ADDRESS_PENDING";
    AddressInfoActionType["SET_ADDRESS_SUCCESS"] = "SET_ADDRESS_SUCCESS";
    AddressInfoActionType["SET_ADDRESS_ERROR"] = "SET_ADDRESS_ERROR";
    AddressInfoActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(AddressInfoActionType || (AddressInfoActionType = {}));
