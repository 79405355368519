export var ReferencesInitialState = {
    id: 0,
    expertId: 0,
    expertName: '',
    clientId: 0,
    clientName: '',
    form: '',
    payload: [],
    createdAt: '',
};
export var initialState = {
    bookings: [],
    isBookingCreated: false,
    isBookingDeleted: false,
    isBookingEdited: false,
    loading: false,
    error: null,
};
