export var ChangesHistoryInitialState = {
    snippets: {
        today: [],
        yesterday: [],
        earlier: [],
    },
};
export var initialState = {
    changes: ChangesHistoryInitialState,
    loading: false,
    error: null,
};
