export var RecordsActionType;
(function (RecordsActionType) {
    RecordsActionType["GET_RECORDS_PENDING"] = "GET_RECORDS_PENDING";
    RecordsActionType["GET_RECORDS_SUCCESS"] = "GET_RECORDS_SUCCESS";
    RecordsActionType["GET_RECORDS_ERROR"] = "GET_RECORDS_ERROR";
    RecordsActionType["ADD_RECORD_PENDING"] = "ADD_RECORD_PENDING";
    RecordsActionType["ADD_RECORD_SUCCESS"] = "ADD_RECORD_SUCCESS";
    RecordsActionType["ADD_RECORD_ERROR"] = "ADD_RECORD_ERROR";
    RecordsActionType["DELETE_RECORD_PENDING"] = "DELETE_RECORD_PENDING";
    RecordsActionType["DELETE_RECORD_SUCCESS"] = "DELETE_RECORD_SUCCESS";
    RecordsActionType["DELETE_RECORD_ERROR"] = "DELETE_RECORD_ERROR";
    RecordsActionType["EDIT_RECORD_PENDING"] = "EDIT_RECORD_PENDING";
    RecordsActionType["EDIT_RECORD_SUCCESS"] = "EDIT_RECORD_SUCCESS";
    RecordsActionType["EDIT_RECORD_ERROR"] = "EDIT_RECORD_ERROR";
    RecordsActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(RecordsActionType || (RecordsActionType = {}));
