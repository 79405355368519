import axios from 'axios';
var token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE3MDc4MzE3ODUsImV4cCI6MTcxMDQyMzc4NSwicm9sZXMiOlsiUk9MRV9DT05TVUxUQU5UIiwiUk9MRV9VU0VSIiwiUk9MRV9DTElFTlQiXSwiaWQiOjEyLCJ1c2VyIjp7ImZ1bGxOYW1lIjp7InVrIjoi0J_RgNC40YjQutC-INCf0LXRgtGA0L4g0JLQsNC70LXRgNGW0LnQvtCy0LjRhyIsInJ1Ijoi0J_RgNC40YjQutC-INCf0LXRgtGA0L4g0JLQsNC70LXRgNGW0LnQvtCy0LjRhyIsImVuIjoi0J_RgNC40YjQutC-INCf0LXRgtGA0L4g0JLQsNC70LXRgNGW0LnQvtCy0LjRhyJ9LCJhdmF0YXIiOiJodHRwczovL2Rldi5tb25paGVhbC5jb20vbWVkaWEvY2FjaGUvcmVzb2x2ZS91c2VyX3RvcF9wYW5lbF9hdmF0YXIvbm9fYXZhdGFyLnN2ZyIsImVtYWlsIjoiY29uc3VsdGFudDEuYWNjb3VudEAxc2VjbWFpbC5jb20ifX0.j9V9DntI9l5g13oY7GbfRtvWQF0LIXcuZPu8qqvdu78_NpIqbM_6aqpCoqI6cLsNCBMZJFrhSKha4TynVxWIrCaN6m2xN0Xzh0ISZS8GZFA12RhPazkXhaR3Eio7lCg3UdCHf25KAJn43hFwWqOdxMqHEYBoRVOImEkiULOGLvW5WJMQFtCYxq5J6NCHLfBqlv9XHBhAd2fycHJcGpSfQ43Od4sw9dmv3W37ee8x98HE8J0qctaNxOtbDBciZ6zhLAkoT8PYacXydDZSbunCe3diA2MhpzHQeXJOwYNw-2zy4N8W0xqXOmbsevzQl2Fe_tAz3T2VybYVAIpkjIbGACMk8Og2Pk5r9qdkSULCAhRoqHoJ1mQIyx7QQM9APZF2cRWXTlFtOTiQv5bcaLHoirDtAGsXpdu2OzLTRERQ8cwibz3mNAJoIz7yoeGbZoSPw7xfc0axjI_Wnzmj8E87WPWv87FpJjMPUs8A8Z8CbjPSOx5Q-rbKASH3dVGLBZAkhxYyMqQT3xo-lfAchM6NvusI8zrO0UUQo9adpFqrhTlLJ_TmauTS4a37HraTxjU93TNjhUi0VbsY8xr4-uxK5TaJthWjr-3N_BcZJLN8IlIBj1EKn6lREsXwy8bki0OJcvlCEBjiBU0VuwkmIBgEM10KY_BhMZmmhVWWJiQ_BQE';
export var instance = axios.create({
    baseURL: '',
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        // Authorization: `Bearer ${token}`,
    },
});
