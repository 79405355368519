export var ModalActionTypes;
(function (ModalActionTypes) {
    ModalActionTypes[ModalActionTypes["ShowGalleryModal"] = 0] = "ShowGalleryModal";
    ModalActionTypes[ModalActionTypes["HideGalleryModal"] = 1] = "HideGalleryModal";
    ModalActionTypes[ModalActionTypes["ShowPhotoModal"] = 2] = "ShowPhotoModal";
    ModalActionTypes[ModalActionTypes["HidePhotoModal"] = 3] = "HidePhotoModal";
    ModalActionTypes[ModalActionTypes["ShowRecordModal"] = 4] = "ShowRecordModal";
    ModalActionTypes[ModalActionTypes["HideRecordModal"] = 5] = "HideRecordModal";
    ModalActionTypes[ModalActionTypes["ShowDeclarationModal"] = 6] = "ShowDeclarationModal";
    ModalActionTypes[ModalActionTypes["HideDeclarationModal"] = 7] = "HideDeclarationModal";
})(ModalActionTypes || (ModalActionTypes = {}));
export function showGalleryModal() {
    return {
        type: ModalActionTypes.ShowGalleryModal,
    };
}
export function hideGalleryModal() {
    return {
        type: ModalActionTypes.HideGalleryModal,
    };
}
export function showPhotoModal() {
    return {
        type: ModalActionTypes.ShowPhotoModal,
    };
}
export function hidePhotoModal() {
    return {
        type: ModalActionTypes.HidePhotoModal,
    };
}
export function showRecordModal() {
    return {
        type: ModalActionTypes.ShowRecordModal,
    };
}
export function hideRecordModal() {
    return {
        type: ModalActionTypes.HideRecordModal,
    };
}
export function showDeclarationModal() {
    return {
        type: ModalActionTypes.ShowDeclarationModal,
    };
}
export function hideDeclarationModal() {
    return {
        type: ModalActionTypes.HideDeclarationModal,
    };
}
