export var ValuesActionType;
(function (ValuesActionType) {
    ValuesActionType["GET_VALUES_PENDING"] = "GET_VALUES_PENDING";
    ValuesActionType["GET_VALUES_SUCCESS"] = "GET_VALUES_SUCCESS";
    ValuesActionType["GET_VALUES_ERROR"] = "GET_VALUES_ERROR";
    ValuesActionType["ADD_VALUES_PENDING"] = "ADD_VALUES_PENDING";
    ValuesActionType["ADD_VALUES_SUCCESS"] = "ADD_VALUES_SUCCESS";
    ValuesActionType["ADD_VALUES_ERROR"] = "ADD_VALUES_ERROR";
    ValuesActionType["DELETE_VALUES_PENDING"] = "DELETE_VALUES_PENDING";
    ValuesActionType["DELETE_VALUES_SUCCESS"] = "DELETE_VALUES_SUCCESS";
    ValuesActionType["DELETE_VALUES_ERROR"] = "DELETE_VALUES_ERROR";
    ValuesActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(ValuesActionType || (ValuesActionType = {}));
