export var AccessesInitialState = {
    id: 0,
    type: '',
    periodStart: '',
    periodEnd: '',
    endDate: '',
    records: [],
    experts: [{ id: 0, avatar: null, lastName: '', firstName: '', category: [''] }],
};
export var AccessesExpertsInitialState = {
    firstName: '',
    lastName: '',
    id: 0,
    avatar: '',
    category: [''],
};
export var initialState = {
    accesses: [AccessesInitialState],
    experts: [AccessesExpertsInitialState],
    isAccessAdded: false,
    isAccessDeleted: false,
    isAccessEdited: false,
    loading: false,
    error: null,
};
