export var PersonalInfoActionType;
(function (PersonalInfoActionType) {
    PersonalInfoActionType["GET_PERSONAL_PENDING"] = "GET_PERSONAL_PENDING";
    PersonalInfoActionType["GET_PERSONAL_SUCCESS"] = "GET_PERSONAL_SUCCESS";
    PersonalInfoActionType["GET_PERSONAL_ERROR"] = "GET_PERSONAL_ERROR";
    PersonalInfoActionType["SET_PERSONAL_PENDING"] = "SET_PERSONAL_PENDING";
    PersonalInfoActionType["SET_PERSONAL_SUCCESS"] = "SET_PERSONAL_SUCCESS";
    PersonalInfoActionType["SET_PERSONAL_ERROR"] = "SET_PERSONAL_ERROR";
    PersonalInfoActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(PersonalInfoActionType || (PersonalInfoActionType = {}));
