var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AddressInfoActionType } from './constants';
var addressInitialState = {
    id: 0,
    oblast: '',
    region: '',
    settlement: '',
    street: '',
    house: '',
    apartment: '',
};
var initialState = {
    address: addressInitialState,
    isDataChanged: false,
    loading: false,
    error: null,
};
export var addressReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AddressInfoActionType.SET_DATA_FALSE:
            return __assign(__assign({}, state), { isDataChanged: false, loading: false, error: null });
        case AddressInfoActionType.GET_ADDRESS_PENDING:
            return __assign(__assign({}, state), { loading: true, error: null });
        case AddressInfoActionType.GET_ADDRESS_SUCCESS:
            return __assign(__assign({}, state), { loading: false, address: action.payload });
        case AddressInfoActionType.GET_ADDRESS_ERROR:
            return __assign(__assign({}, state), { loading: false, error: action.payload });
        case AddressInfoActionType.SET_ADDRESS_PENDING:
            return __assign(__assign({}, state), { isDataChanged: false, loading: true, error: null });
        case AddressInfoActionType.SET_ADDRESS_SUCCESS:
            return __assign(__assign({}, state), { isDataChanged: true, loading: false, address: action.payload });
        case AddressInfoActionType.SET_ADDRESS_ERROR:
            return __assign(__assign({}, state), { isDataChanged: false, loading: false, error: action.payload });
        default:
            return state;
    }
};
