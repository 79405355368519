import { makeStyles } from '@mui/styles';
export var useStyles = makeStyles(function (theme) { return ({
    MainWrapper: {
        width: '100%',
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        flexDirection: 'row',
        marginTop: '10px',
        marginBottom: '10px',
        marginRight: '32px',
        borderRadius: '20px',
    },
    inputRoot: {
        fontSize: 14,
        fontWeight: 500,
        overflow: 'hidden',
        letterSpacing: '-0.03em',
        paddingLeft: '10px',
        '&::placeholder': {
            color: '#90A4BE',
            opacity: 1,
        },
    },
    SearchField: {
        margin: '10px',
        display: 'flex',
        justifyContent: 'center',
        height: '40px',
        width: '358px',
        borderRadius: '30px',
        borderColor: 'transparent',
        backgroundColor: '#F1F6FA',
    },
    ContentCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px',
    },
    ButtonHeader: {
        backgroundColor: '#F1F6FA',
        width: '40px',
        height: '40px',
        boxShadow: 'none',
    },
    CabinetWrapper: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '30px',
        justifyContent: 'left',
        height: '40px',
        width: '136px',
        backgroundColor: '#173236',
        padding: '4px 10px 4px 4px',
    },
    customTypography: {
        fontFamily: 'GraphikLCG',
        color: 'white',
        fontWeight: '600',
        fontSize: '14px',
        letterSpacing: '-0.03em',
    },
    expandMenuParent: {
        width: '240px',
        backgroundColor: '#FFFFFF',
        borderRadius: '15px',
        zIndex: '999',
        padding: '10px',
    },
    menuHeaderTextBig: {
        fontWeight: '500',
        textAlign: 'left',
        color: '#173236',
        fontFamily: 'GraphikLCG',
        letterSpacing: '-0.03em',
        lineHeight: '14px',
        fontSize: '14px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    menuHeaderTextBigMarginTop: {
        fontWeight: '500',
        textAlign: 'left',
        color: '#173236',
        marginTop: '20px',
        fontFamily: 'GraphikLCG',
        letterSpacing: '-0.03em',
        lineHeight: '14px',
        fontSize: '14px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    menuHeaderTextBigExit: {
        fontWeight: '500',
        textAlign: 'left',
        color: '#777E90',
        marginTop: '20px',
        fontFamily: 'GraphikLCG',
        letterSpacing: '-0.03em',
        lineHeight: '14px',
        fontSize: '14px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    menuHeaderTextSmall: {
        fontSize: '12px',
        fontWeight: '500',
        textAlign: 'left',
        color: '#173236',
        fontFamily: 'GraphikLCG',
        letterSpacing: '-0.03em',
        lineHeight: '14px',
    },
    MenuContentWhiteButton: {
        display: 'flex',
        width: '220px',
        height: '60px',
        borderRadius: '10px',
        padding: '10px',
        border: '1px solid #E5EEFF',
        marginTop: '10px',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'left',
        color: '#173236',
        fontFamily: 'GraphikLCG',
        letterSpacing: '-0.03em',
        lineHeight: '18px',
    },
    MenuContentDarkButton: {
        display: 'flex',
        width: '220px',
        height: '50px',
        borderRadius: '10px',
        padding: '10px',
        backgroundColor: '#173236',
        border: '1px solid #E5EEFF',
        marginTop: '10px',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'left',
        fontFamily: 'GraphikLCG',
        letterSpacing: '-0.03em',
        lineHeight: '18px',
        justifyContent: 'space-between',
    },
    endIcon: {
        marginLeft: '30px',
    },
    MenuContentButtonWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
    },
    MenuContent: {
        width: '220px',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '10px',
        padding: '10px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    CollapseMenu: {
        display: 'flex',
        flexDirection: 'column',
    },
    CollapseMenuItem: {
        display: 'flex',
        gap: '10px',
        marginTop: '15px',
        flexDirection: 'row',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    CollapseMenuText: {
        fontWeight: '500',
        color: '#1E1E1E',
        fontSize: '12px',
        lineHeight: '14px',
        fontFamily: 'GraphikLCG',
        letterSpacing: '-0.03em',
    },
}); });
