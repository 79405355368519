var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Box, Button, Popover, Typography } from '@mui/material';
import { useStyles } from '../HeaderCabinetStyle';
import Collapse from '@material-ui/core/Collapse';
import { Arrow } from '../assets/Arrow';
import { ItemCollapse } from './ItemCollapse';
import { CollapseMenuMedicalArchiveIcon } from '../assets/CollapseMenuMedicalArchiveIcon';
import { CollapseMenuDynamicIcon } from '../assets/CollapseMenuDynamicIcon';
import { CollapseMenuToDoctorsIcon } from '../assets/CollapseMenuToDoctorsIcon';
import { CollapseMenuDirectionIcon } from '../assets/CollapseMenuDirectionIcon';
import { CollapseMenuReferencesIcon } from '../assets/CollapseMenuReferencesIcon';
import { CollapseMenuFamilyIcon } from '../assets/CollapseMenuFamilyIcon';
import { ArrowDown } from '../assets/ArrowDown';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IsExpertSelector, PersonalInfoSelector } from '../../../../../app/moi/store/user/personalInfo/selector';
import { setCabinetType } from '../../../../../app/moi/store/cabinet/factory';
import { CabinetSelector } from '../../../../../app/moi/store/cabinet/selector';
import ArrowRightIconSvg from '../assets/ArrowRightIconSvg';
export var CabinetProfileMenu = function (_a) {
    var _b, _c;
    var handleClose = _a.handleClose, open = _a.open, id = _a.id, anchorEl = _a.anchorEl;
    var dispatch = useDispatch();
    var classes = useStyles();
    var _d = __read(React.useState(false), 2), collapseExpanded = _d[0], setCollapseExpanded = _d[1];
    var isExpert = useSelector(IsExpertSelector);
    var personalInfo = useSelector(PersonalInfoSelector);
    var cabinetType = useSelector(CabinetSelector);
    var history = useHistory();
    var CollapseMenu = function () {
        if (collapseExpanded)
            setCollapseExpanded(false);
        else
            setCollapseExpanded(true);
    };
    var handleCabinetType = function (cabinetType) {
        dispatch(setCabinetType(cabinetType));
    };
    return (React.createElement(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: -20,
            horizontal: 'right',
        }, classes: {
            paper: classes.expandMenuParent,
        } },
        React.createElement(Box, { className: classes.MenuContent, sx: {
                height: '100%',
                backgroundColor: '#F1F6FA',
            }, onClick: function () {
                history.push('/uk/cabinet/settings');
                handleClose;
            } },
            React.createElement(Box, { className: classes.MenuContentButtonWrapper },
                React.createElement(Typography, { className: classes.menuHeaderTextBig },
                    personalInfo.last_name,
                    " ", (_b = personalInfo.first_name) === null || _b === void 0 ? void 0 :
                    _b.slice(0, 1).toUpperCase(),
                    ".",
                    ' ', (_c = personalInfo.second_name) === null || _c === void 0 ? void 0 :
                    _c.slice(0, 1).toUpperCase(),
                    "."),
                React.createElement(Typography, { className: classes.menuHeaderTextSmall }, personalInfo.email)),
            React.createElement(Arrow, { color: '#A5BDDB' })),
        cabinetType !== 'doctor' ? (isExpert ? (React.createElement(Button, { sx: { '& .MuiButton-startIcon': { margin: '0px' }, minWidth: 0, color: '#FFFFFF' }, classes: { endIcon: classes.endIcon }, endIcon: React.createElement(ArrowRightIconSvg, { color: '#FFFFFF' }), className: classes.MenuContentDarkButton, onClick: function () { return handleCabinetType('doctor'); } }, "\u0412 \u043A\u0430\u0431\u0456\u043D\u0435\u0442 \u043B\u0456\u043A\u0430\u0440\u044F")) : (
        //<Box
        //  className={classes.MenuContent}
        //  sx={{
        //    backgroundColor: '#173236 !important',
        //    border: '1px solid #E5EEFF',
        //    marginTop: '10px',
        //  }}
        //>
        //  <Box className={classes.MenuContentButtonWrapper} onClick={() => handleCabinetType('doctor')}>
        //    <Typography className={classes.menuHeaderTextBig} sx={{ color: 'white !important' }}>
        //      В кабінет лікаря
        //    </Typography>
        //  </Box>
        //  <Arrow color={'#29F499'} />
        //</Box>
        React.createElement(Button, { sx: { '& .MuiButton-startIcon': { margin: '0px' }, minWidth: 0 }, classes: { endIcon: classes.endIcon }, endIcon: React.createElement(ArrowRightIconSvg, null), className: classes.MenuContentWhiteButton, onClick: function () { return (document.location.href = '/uk/register'); } }, "\u0421\u0442\u0432\u043E\u0440\u0438\u0442\u0438 \u043A\u0430\u0431\u0456\u043D\u0435\u0442 \u043B\u0456\u043A\u0430\u0440\u044F \u0430\u0431\u043E \u043A\u043B\u0456\u043D\u0456\u043A\u0438")
        //<Box
        //  className={classes.MenuContent}
        //  sx={{
        //    border: '1px solid #E5EEFF',
        //    marginTop: '10px',
        //  }}
        //>
        //  {/*<Box
        //    className={classes.MenuContentButtonWrapper}
        //    //onClick={() => history.push('/uk/register')}
        //    onClick={() => (document.location.href = '/uk/register')}
        //  >
        //    <Typography className={classes.menuHeaderTextBig} sx={{ width: '150px' }}>
        //      Створити кабінет лікаря або клініки
        //    </Typography>
        //  </Box>*/}
        //</Box>
        )) : (React.createElement(Button, { sx: { '& .MuiButton-startIcon': { margin: '0px' }, minWidth: 0, color: '#29F499' }, classes: { endIcon: classes.endIcon }, endIcon: React.createElement(ArrowRightIconSvg, { color: '#29F499' }), className: classes.MenuContentDarkButton, onClick: function () { return handleCabinetType('patient'); } }, "\u0412 \u043A\u0430\u0431\u0456\u043D\u0435\u0442 \u043F\u0430\u0446\u0456\u0454\u043D\u0442\u0430")
        //<>
        //  <Box
        //    className={classes.MenuContent}
        //    sx={{
        //      backgroundColor: '#173236 !important',
        //      border: '1px solid #E5EEFF',
        //      marginTop: '10px',
        //    }}
        //  >
        //    <Box className={classes.MenuContentButtonWrapper} onClick={() => handleCabinetType('patient')}>
        //      <Typography className={classes.menuHeaderTextBig} sx={{ color: 'white !important' }}>
        //        В кабінет пацієнта
        //      </Typography>
        //    </Box>
        //    <Arrow color={'#29F499'} />
        //  </Box>
        //</>
        ),
        React.createElement(Box, { sx: { margin: '20px' } },
            React.createElement(Box, { onClick: CollapseMenu, sx: {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                } },
                collapseExpanded ? React.createElement(ArrowDown, null) : React.createElement(Arrow, { color: '#173236' }),
                React.createElement(Typography, { sx: { paddingLeft: '10px' }, className: classes.menuHeaderTextBig }, "\u041E\u0441\u043D\u043E\u0432\u043D\u0435 \u043C\u0435\u043D\u044E")),
            cabinetType === 'patient' ? (React.createElement(Collapse, { in: collapseExpanded, className: classes.CollapseMenu },
                React.createElement(ItemCollapse, { handleClose: handleClose, icon: React.createElement(CollapseMenuMedicalArchiveIcon, null), text: 'Медичний архів', route: '/uk/cabinet/data_archive#nav-my-records' }),
                React.createElement(ItemCollapse, { handleClose: handleClose, icon: React.createElement(CollapseMenuDynamicIcon, null), text: 'Динаміка здоровя', route: '/uk/cabinet/my-health-dynamic' }),
                React.createElement(ItemCollapse, { handleClose: handleClose, icon: React.createElement(CollapseMenuToDoctorsIcon, null), text: 'Записи до лікарів', route: '/uk/cabinet/my-booked-services' }),
                React.createElement(ItemCollapse, { handleClose: handleClose, icon: React.createElement(CollapseMenuDirectionIcon, null), text: 'Направлення', route: '/uk/cabinet/my-analyse' }),
                React.createElement(ItemCollapse, { handleClose: handleClose, icon: React.createElement(CollapseMenuReferencesIcon, null), text: 'Довідки', route: '/uk/cabinet/reference' }),
                React.createElement(ItemCollapse, { handleClose: handleClose, icon: React.createElement(CollapseMenuFamilyIcon, null), text: 'Моя родина', route: '/uk/cabinet/charges' }))) : (React.createElement(Collapse, { in: collapseExpanded, className: classes.CollapseMenu },
                React.createElement(ItemCollapse, { handleClose: handleClose, icon: React.createElement(CollapseMenuMedicalArchiveIcon, null), text: 'Медичний архів', route: '/uk/cabinet/data_archive#nav-my-records' }),
                React.createElement(ItemCollapse, { handleClose: handleClose, icon: React.createElement(CollapseMenuDynamicIcon, null), text: 'Динаміка здоровя', route: '/uk/cabinet/my-health-dynamic' }),
                React.createElement(ItemCollapse, { handleClose: handleClose, icon: React.createElement(CollapseMenuToDoctorsIcon, null), text: 'Записи до лікарів', route: '/uk/cabinet/my-booked-services' }),
                React.createElement(ItemCollapse, { handleClose: handleClose, icon: React.createElement(CollapseMenuDirectionIcon, null), text: 'Направлення', route: '/uk/cabinet/my-analyse' }),
                React.createElement(ItemCollapse, { handleClose: handleClose, icon: React.createElement(CollapseMenuReferencesIcon, null), text: 'Довідки', route: '/uk/cabinet/reference' }),
                React.createElement(ItemCollapse, { handleClose: handleClose, icon: React.createElement(CollapseMenuFamilyIcon, null), text: 'Моя родина', route: '/uk/cabinet/charges' })))),
        React.createElement(Box, { sx: { margin: '10px', display: 'flex', flexDirection: 'column', marginLeft: '20px', gap: '20px' } },
            React.createElement(Typography, { className: classes.menuHeaderTextBigMarginTop, onClick: function () {
                    history.push('/uk/cabinet/settings');
                    handleClose();
                } }, "\u041D\u0430\u043B\u0430\u0448\u0442\u0443\u0432\u0430\u043D\u043D\u044F"),
            React.createElement(Typography, { className: classes.menuHeaderTextBigMarginTop, onClick: function () {
                    document.location = '/uk/faq';
                    handleClose();
                } }, "\u0414\u043E\u043F\u043E\u043C\u043E\u0433\u0430"),
            React.createElement(Typography, { className: classes.menuHeaderTextBigExit, onClick: function () {
                    document.cookie = "cabinetType=''";
                    //history.push('/uk/logout');
                    document.location = '/uk/logout';
                } }, "\u0412\u0438\u0439\u0442\u0438"))));
};
