export var patientMenuItems = [
    {
        title: 'Медичний архів',
        link: '/uk/cabinet/data_archive',
        icon: 'archiveMenu',
    },
    //{
    //  title: 'Скринінг та моніторинг',
    //  link: '/uk/cabinet/screaning-screaning',
    //  icon: 'screeningMenu',
    //},
    {
        title: 'Динаміка здоровʼя',
        link: '/uk/cabinet/my-health-dynamic',
        icon: 'dynamicMenu',
    },
    {
        title: 'Записи до лікарів',
        link: '/uk/cabinet/my-booked-services',
        icon: 'appointmentsMenu',
    },
    //{
    //  title: 'Направлення',
    //  link: '/uk/cabinet/my-analyse',
    //  icon: 'referralsMenu',
    //},
    {
        title: 'Довідки',
        link: '/uk/cabinet/reference',
        icon: 'referenceMenu',
    },
    {
        title: 'Моя родина',
        link: '/uk/cabinet/charges',
        icon: 'familyMenu',
    },
    {
        title: 'Повідомлення',
        link: '/uk/cabinet/messages',
        icon: 'chatMenu',
        reloadPage: true,
    },
    {
        title: 'Сповіщення',
        link: '/uk/cabinet/notification/all',
        icon: 'notificationMenu',
    },
];
export var doctorMenuItems = [
    {
        title: 'Записи пацієнтів',
        link: '/uk/cabinet/booked-services',
        icon: 'appointmentsMenu',
    },
    {
        title: 'Графік прийомів',
        link: '/uk/cabinet/schedule',
        icon: 'calendarMenu',
    },
    {
        title: 'Пацієнти',
        link: '/uk/cabinet/clients',
        icon: 'familyMenu',
    },
    {
        title: 'Послуги',
        link: '/uk/cabinet/service',
        icon: 'archiveMenu',
    },
    //{
    //  title: 'Направлення',
    //  link: '/uk/cabinet/referral',
    //  icon: 'referralsMenu',
    //},
    {
        title: 'Довідки',
        link: '/uk/cabinet/reference',
        icon: 'referenceMenu',
    },
    //{
    //  title: 'EHealth',
    //  link: '/uk/e-health/app',
    //  icon: 'briefcaseMenu',
    //},
    {
        title: 'Повідомлення',
        link: '/uk/cabinet/messages',
        icon: 'chatMenu',
        reloadPage: true,
    },
    {
        title: 'Сповіщення',
        link: '/uk/cabinet/notification/all',
        icon: 'notificationMenu',
    },
];
export var clinicMenuItems = [
    {
        title: 'Записи пацієнтів',
        link: '/uk/cabinet/company/patient-entries',
        icon: 'appointmentsMenu',
    },
    {
        title: 'Наші послуги',
        link: '/uk/cabinet/company/services',
        icon: 'archiveMenu',
    },
    {
        title: 'Лікарі',
        link: '/uk/cabinet/company/experts-new',
        icon: 'familyMenu',
    },
    {
        title: 'Графік прийомів',
        link: '/uk/cabinet/company/experts-schedule',
        icon: 'calendarMenu',
    },
    {
        title: 'Новини',
        link: '/uk/cabinet/company/news',
        icon: 'referralsMenu',
    },
    {
        title: 'Кабінет закладу',
        link: '/uk/cabinet/company/profile/new',
        icon: 'building-03',
    },
    {
        title: 'Відгуки',
        link: '/uk/cabinet/company/reviews',
        icon: 'star-square',
    },
    {
        title: 'Фотогалерея',
        link: '/uk/cabinet/company/gallery',
        icon: 'album-02',
    },
    {
        title: 'Медзаклад в eHealth',
        link: '/uk/cabinet/company/legal-entity',
        icon: 'first-aid-kit',
    },
    //{
    //  title: 'EHealth',
    //  link: '/uk/cabinet/company/patient-entries', // не зрозуміло що має тут бути (немає макету)
    //  icon: 'briefcaseMenu',
    //},
    {
        title: 'Сповіщення',
        link: '/uk/cabinet/notification/all',
        icon: 'notificationMenu',
    },
];
