export var InvitesActionType;
(function (InvitesActionType) {
    InvitesActionType["GET_INVITES_PENDING"] = "GET_INVITES_PENDING";
    InvitesActionType["GET_INVITES_SUCCESS"] = "GET_INVITES_SUCCESS";
    InvitesActionType["GET_INVITES_ERROR"] = "GET_INVITES_ERROR";
    InvitesActionType["ADD_INVITE_PENDING"] = "ADD_INVITE_PENDING";
    InvitesActionType["ADD_INVITE_SUCCESS"] = "ADD_INVITE_SUCCESS";
    InvitesActionType["ADD_INVITE_ERROR"] = "ADD_INVITE_ERROR";
    InvitesActionType["DELETE_INVITE_PENDING"] = "DELETE_INVITE_PENDING";
    InvitesActionType["DELETE_INVITE_SUCCESS"] = "DELETE_INVITE_SUCCESS";
    InvitesActionType["DELETE_INVITE_ERROR"] = "DELETE_INVITE_ERROR";
    InvitesActionType["SET_DATA_FALSE"] = "SET_DATA_FALSE";
})(InvitesActionType || (InvitesActionType = {}));
