export var ModulesInitialState = {
    id: 0,
    title: '',
    description: '',
    url: '',
};
export var initialState = {
    modules: [ModulesInitialState],
    loading: false,
    error: null,
};
